const elementInView = (el, dividend = 1) => {
  const elementTop = el.getBoundingClientRect().top;

  return (elementTop <= (window.innerHeight) / dividend);
};

const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;

  return (elementTop > (window.innerHeight || document.documentElement.clientHeight));
};

const displayScrollElement = (element) => {
  element.classList.add('scrolled');
};

const hideScrollElement = (element) => {
  element.classList.remove('scrolled');
};

// eslint-disable-next-line no-unused-vars
const handleScrollAnimation = (element) => {
  const scrollElements = element.querySelectorAll('[data-scroll]');

  scrollElements.forEach((el) => {
    const elDelay = el.getAttribute('data-delay');

    if (elementInView(el, 0.95)) {
      if (elDelay) {
        // eslint-disable-next-line no-param-reassign
        el.style.transitionDelay = `${elDelay}ms`;
      }
      displayScrollElement(el);
    } else if (elementOutofView(el)) {
      hideScrollElement(el);
    }
  });
};

function buttonTextMobileClick(element) {
  let button = element.getElementsByClassName('text-text__read-more')[0];
  let text = element.getElementsByClassName('text-text__intro')[0];
  button.classList.add('clicked');
  text.classList.add('loaded');
}

const textMobileLoadMoreAnimation = (element) => {
  let button = element.getElementsByClassName('text-text__read-more')[0];
  button.addEventListener('click', () => {buttonTextMobileClick(element)});
}

const init = () => {
  if (document.querySelectorAll('[data-scroll]').length) {
    window.addEventListener('scroll', () => {
      handleScrollAnimation(document.body);
    });

    handleScrollAnimation(document.body);
  }

  let texts = document.getElementsByClassName('text-text');
  Array.prototype.forEach.call(texts, function(text) {
    textMobileLoadMoreAnimation(text);
  });
};

export { handleScrollAnimation };

export default {
  init,
};
