import Swiper from 'swiper/bundle';
// eslint-disable-next-line no-unused-vars
let eventsSwiper;

function initEventsCarousel(el) {
  // eslint-disable-next-line no-unused-vars
  eventsSwiper = new Swiper(el, {
    // loop: true,
    slidesPerView: 'auto',
    preventInteractionOnTransition: true,
    notificationClass: false,
    autoplay: false,
    slidesPerGroup: 1,
    mousewheel: {
      forceToAxis: true,
      thresholdDelta: 20
    },
    keyboard: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  return eventsSwiper
}

const EventsCarouselDisable = () => {
  if (window.innerWidth < 768) {
    eventsSwiper.disable();
    document.querySelector('.events__carousel').classList.add('events__carousel--disabled');
  } else {
    eventsSwiper.enable();
    document.querySelector('.events__carousel').classList.remove('events__carousel--disabled');
  }
};

const events = () => {
  window.addEventListener('resize', EventsCarouselDisable);
};

const activeSwiper = () => {
  document.querySelectorAll('.js-swiper-active').forEach(function(el) {
    el.addEventListener('click', () => {
      setTimeout(function() {
        initEventsCarousel();
      }, 1000);
    });
  });
};

const init = () => {
  const carousels = document.querySelectorAll('.events__carousel')
  if (carousels.length) {
    carousels.forEach(el => {
      initEventsCarousel(el);
      events();
      EventsCarouselDisable();
      activeSwiper();
    })
  }

  const carouselsMod = document.querySelectorAll('.events__carousel_mod')
  if (carouselsMod.length) {
    carouselsMod.forEach(el => {
      initEventsCarousel(el);
      activeSwiper();
    })
  }
};

export default {
  init,
};
