const init = () => {
  if (document.getElementsByClassName('entry__caption').length) {
    const entryCaptions = document.querySelectorAll('.entry__caption-text');

    entryCaptions.forEach((caption) => {
      const entryCaptionLength = caption.querySelectorAll('p').length
      const captionWrap = caption.closest('.entry__caption');

      if (entryCaptionLength < 2 && captionWrap) {
        captionWrap.querySelector('.read-more').style.display = 'none';
      } else {
        captionWrap.querySelector('.read-more').style.display = 'inline-block';
      }
    })
  }
};

export default {
  init,
};
