let heroSection;

const toggleLineUpItem = (target) => {
  target.classList.toggle('entry__lineup-item--active');
};

const toggleEntryCaption = (link) => {
  const entryCaption = link.closest('.entry__caption').querySelector('.entry__caption-text');

  link.classList.toggle('read-more--active');
  entryCaption.classList.toggle('entry__caption-text--active');
};

const catchLink = (e) => {
  const targetElem = e.target;

  if (targetElem.classList.contains('read-more')) {
    e.preventDefault();
    toggleEntryCaption(targetElem);
  }

  if (targetElem.classList.contains('entry__lineup-name')) {
    e.preventDefault();

    targetElem.classList.toggle('entry__lineup-name--active');
    toggleLineUpItem(targetElem.closest('.entry__lineup-item'));
  }
};

const setMobileBg = () => {
  heroSection = document.querySelector('.hero');

  if (window.innerWidth < 768 && heroSection) {
    heroSection.style.backgroundImage = `url(${heroSection.getAttribute('data-mob-bg')})`;
  }
};

const events = () => {
  document.addEventListener('click', catchLink);
  window.addEventListener('resize', setMobileBg);
};

const init = () => {
  if (document.getElementsByClassName('js-entry-modal')) {
    events();
  }

  window.addEventListener('popstate', (event) => {
    if (event.target.location.href) {
      window.location.href = event.target.location.href;
    }
  });

  // eslint-disable-next-line no-unused-vars
  window.addEventListener('load', (event) => {
    setTimeout(() => {
      document.body.classList.add('window-loaded');
    }, 100);

    setMobileBg();
  });
};

export default {
  init,
};
