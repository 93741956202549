// eslint-disable-next-line import/extensions
import { handleScrollAnimation } from '../animation';
import Events from '../events';


let modalState;
const entryModal = document.getElementById('entry-modal');
const entryModalContent = entryModal.querySelector('.modal__inner');

const clearModal = () => {
  entryModalContent.innerHTML = '';
};

// eslint-disable-next-line no-unused-vars
const toggleEntryModal = (action) => {
  if (!action) {
    document.body.classList.remove('modal-show');
    modalState = false;
    window.history.pushState(false, '', '/');
    clearModal();
  } else {
    document.body.classList.add('modal-show');
    modalState = true;
    Events.init()
  }
};

const getEntry = (link) => {
  const url = link.getAttribute('href') ? link.getAttribute('href') : link.getAttribute('data-url');

  if (url) {
    fetch(url, {
      method: 'GET',
      cache: 'no-cache',
    }).then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        // eslint-disable-next-line no-param-reassign
        const parseHtml = parser.parseFromString(data, 'text/html');

        if (data) {
          // eslint-disable-next-line no-restricted-globals
          history.pushState(false, '', url);
          // eslint-disable-next-line no-use-before-define
          renderEntryModal(parseHtml.querySelector('.entry'));
        }
      });
  }
};

const renderEntryModal = (entry) => {
  if (entry) {
    entryModalContent.innerHTML = '';
    entryModalContent.appendChild(entry);

    const entryCaptions = document.querySelectorAll('.entry__caption-text');

    entryCaptions.forEach((caption) => {
      const entryCaptionLength = caption.querySelectorAll('p').length
      const captionWrap = caption.closest('.entry__caption');

      if (entryCaptionLength < 2 && captionWrap) {
        captionWrap.querySelector('.read-more').style.display = 'none';
      } else {
        captionWrap.querySelector('.read-more').style.display = 'inline-block';
      }
    })

    toggleEntryModal(true);
  }
};

const catchEscape = (e) => {
  // eslint-disable-next-line eqeqeq
  if (e.type == 'keydown' && e.key === 'Escape' && modalState) {
    toggleEntryModal();
  }
};

const catchLink = (e) => {
  let targetElem = e.target;

  // eslint-disable-next-line eqeqeq
  if (targetElem.classList.contains('js-entry-modal')) {
    e.preventDefault();
    getEntry(e.target.closest('.js-entry-modal'));
  }
  if (targetElem.parentNode.classList) {
    if (targetElem.parentNode.classList.contains('js-entry-modal')) {
      e.preventDefault();
      getEntry(e.target.closest('.js-entry-modal'));
    }
  }

  if (targetElem.classList.contains('entry__close') && modalState) {
    e.preventDefault();
    toggleEntryModal();
  }

  if (!targetElem.closest('.modal__inner')) {
    if (modalState) {
      e.preventDefault();
      toggleEntryModal();
    }
  }
};

const events = () => {
  document.addEventListener('click', catchLink);
  document.addEventListener('keydown', catchEscape);
};

const init = () => {
  const modalWindow = document.getElementById('entry-modal');

  if (document.getElementsByClassName('js-entry-modal')) {
    events();
  }

  modalWindow.addEventListener('scroll', () => {
    handleScrollAnimation(modalWindow);
  });
};

export default {
  init,
};
