import '../styles/main.scss';
import * as Sentry from '@sentry/browser'

import Components from '../components';

const isProduction = process.env.NODE_ENV === 'production'
if (isProduction) {
  Sentry.init({
    dsn: 'https://eca724dc7e0a45e0a36341ee54804d4a@sentry.yournext.agency/42'
  })
}

if (Components.length) {
  Components.forEach((component) => component.init());
}