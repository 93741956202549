// eslint-disable-next-line import/extensions
const Cookies = require('js-cookie');
let popupState = false;
const modal = document.getElementById('content-modal');

// eslint-disable-next-line no-unused-vars
const toggleModal = (action) => {
  if (popupState) {
    document.body.classList.remove('popup-modal-show');
    popupState = false;
    setCookies();
  } else {
    document.body.classList.add('popup-modal-show');
    popupState = true;
  }
};

const setCookies = () => {
  const style = modal.dataset.ctaStyle
  Cookies.set('popup-' + style, true, {expires: 30});
}

const catchEscape = (e) => {
  // eslint-disable-next-line eqeqeq
  if (e.type == 'keydown' && e.key === 'Escape' && popupState) {
    toggleModal();
  }
};

const catchLink = (e) => {
  const targetElem = e.target;

  if (targetElem.parentElement.classList.contains('popup__close') && popupState) {
    e.preventDefault();
    toggleModal();
  }

  if (!targetElem.closest('.modal__inner')) {
    if (popupState) {
      e.preventDefault();
      toggleModal();
    }
  }
};

const events = () => {
  document.addEventListener('click', catchLink);
  document.addEventListener('keydown', catchEscape);
  document.addEventListener('DOMContentLoaded', function() {
    const mcScript = document.createElement('script');
    mcScript.src = 'https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    document.body.appendChild(mcScript);
  });
  if (Cookies.get('popup-' + modal.dataset.ctaStyle) === undefined) {
    setTimeout(() => toggleModal(true), modal.dataset.delay);
  }
};

const init = () => {
  if (modal) {
    events();
  }
};

export default {
  init,
};
