const init = () => {
  // if (!document.querySelector('.hero') && document.querySelector('.header__nav-list')) {
  //   document.querySelector('.header__nav-list').classList.add('header__nav-list--dark');
  //   document.querySelector('.header__burger').classList.add('header__burger--dark');
  // }

  const autoplayVideo = document.querySelector('.js-autoplay-video')
  if (autoplayVideo === null) {
    return false
  }
  autoplayVideo.setAttribute('autoplay', 'autoplay');
};

export default {
  init,
};
