import Swiper from 'swiper/bundle';
// eslint-disable-next-line no-unused-vars
let scheduleSwiper;

// const scheduleLink = document.querySelector('.schedule__item-link');

const initScheduleCarousel = () => {
  scheduleSwiper = new Swiper('.schedule__carousel', {
    // loop: true,
    slidesPerView: 'auto',
    notificationClass: false,
    autoplay: false,
    slidesPerGroup: 1,
    mousewheel: {
      forceToAxis: true,
      thresholdDelta: 20
    },
    keyboard: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
};

// const ScheduleCarouselDisable = () => {
//   // eslint-disable-next-line eqeqeq
//   if (window.innerWidth < 768) {
//     document.querySelector('.schedule__carousel').classList.add('schedule__carousel--disabled');
//     scheduleSwiper.disable();
//   } else {
//     scheduleSwiper.enable();
//     document.querySelector('.schedule__carousel').classList.remove('schedule__carousel--disabled');
//   }
// };

const showEventPopup = (e) => {
  // console.log('dawdaw');
  e.preventDefault();
};

const catchLink = (e) => {
  // eslint-disable-next-line no-console

  // eslint-disable-next-line eqeqeq
  if (e.target.className == 'schedule__item-link') {
    showEventPopup();
  }
};

const events = () => {
  document.addEventListener('click', catchLink);
  // window.addEventListener('resize', ScheduleCarouselDisable);
};

const init = () => {
  if (document.querySelector('.schedule__carousel')) {
    initScheduleCarousel();
    // ScheduleCarouselDisable();
    events();
  }
};

export default {
  init,
};
