/* eslint-disable */
let isVisible = false;

const navSwitcher = () => {
  const headerBurger = document.querySelector('.header__burger');
  // eslint-disable-next-line no-undef
  const htmlDoc = document.getElementsByTagName('html')[0];

  if (isVisible) {
    htmlDoc.classList.remove('show-nav');
    headerBurger.classList.remove('header__burger--active');
    isVisible = false;
  } else {
    isVisible = true;
    htmlDoc.classList.add('show-nav');
    headerBurger.classList.add('header__burger--active');
  }
};

const toggleItem = (e) => {
  if (!e.classList.contains('header__nav-link-open')) {
    Array.from(document.getElementsByClassName('header__nav-link-open')).forEach((element, index, array) => {
      element.classList.remove('header__nav-link-open');
    });
    e.classList.add('header__nav-link-open');
    if (e.nextElementSibling) {
      e.nextElementSibling.classList.add('header__nav-link-open');
    }
  } else {
    Array.from(document.getElementsByClassName('header__nav-link-open')).forEach((element, index, array) => {
      element.classList.remove('header__nav-link-open');
    });
  }
}

const catchNavCollapse = (e) => {
  const targetElem = e.target;

  if (e.type == 'click' && targetElem.classList.contains('header__nav-link') ** targetElem.classList.contains('has-children')) {
    toggleItem(targetElem);
  }
}

const catchEvent = (e) => {
  const targetElem = e.target;

  // eslint-disable-next-line eqeqeq
  if (e.type == 'click' && targetElem.classList.contains('header__burger')) {
    e.preventDefault();
    navSwitcher();
  }

  if (e.type === 'keydown' && e.key === 'Escape' && isVisible) {
    navSwitcher();
  }
};

const events = () => {
  document.addEventListener('click', catchEvent);
  document.addEventListener('keydown', catchEvent);
  document.addEventListener('click', catchNavCollapse);
};

const init = () => {
  if (document.querySelector('.header__burger')) {
    events();
  }

  const navLv = document.querySelector('.header__nav-lv2')
  if (navLv) {
    document.querySelectorAll('.header__nav-lv2')
      .forEach(link => {
        link.previousElementSibling.classList.add('header__nav-lv-wr')
        link.previousElementSibling.addEventListener('click', (event) => {
          event.preventDefault()
        })
      })
  }

};

export default {
  init,
};
