const faqSwitcher = (item) => {
  item.classList.toggle('faq__list-title--active');
};

const catchLink = (e) => {
  const targetElem = e.target;

  if (targetElem.classList.contains('faq__list-title')) {
    e.preventDefault();
    faqSwitcher(targetElem);
  }
};

const events = () => {
  document.addEventListener('click', catchLink);
};

const init = () => {
  if (document.querySelector('.faq')) {
    events();
  }
};

export default {
  init,
};
