// // Header
// // import Header from './header';
// // import Diseases from './diseases';
import Schedule from './schedule';
import Events from './events';
import EntryModal from './entryModal';
import Entry from './entry';
import Hero from './hero';
import Faq from './faq';
import Navigation from './navigation';
import Common from './common';
import Animation from './animation';
import Modal from './modal';

export default [
  Schedule,
  Events,
  EntryModal,
  Entry,
  Hero,
  Faq,
  Navigation,
  Common,
  Animation,
  Modal,
];
